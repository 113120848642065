<template>
  <div class="d-flex justify-end">
    <v-btn
      id="create-classroom-event"
      class="quick-action-button"
      color="primary"
      @click="isNewClassDialogShow = true"
    >
      <v-icon icon="mdi-plus-box-outline" />New Class
    </v-btn>
  </div>
  <div class="d-flex mx-3">
    <v-select
      v-model="instructorFilter"
      label="Instructor"
      :items="instructors"
      max-width="300"
      variant="outlined"
    ></v-select>
  </div>
  <vue-cal
    :time-from="5 * 60"
    :time-to="22 * 60"
    :disable-views="['years', 'year', 'month']"
    active-view="week"
    today-button
    :events="events"
    :on-event-click="onEventClick"
    :dblclick-to-navigate="false"
    @ready="onVueCalReady"
    @view-change="onVueCalViewChange"
  />
  <v-bottom-sheet v-model="isOffCanvasShow" inset>
    <v-sheet class="pa-5">
      <div class="d-flex">
        <div>
          <h2>
            {{ selectedEvent.event.title }} -
            {{ selectedEvent.event.instructor }}
          </h2>
          <div>
            {{ selectedEvent.event.classPeriod }}
          </div>
        </div>
        <div class="ms-auto d-flex flex-column">
          <v-btn
            class="quick-action-button"
            color="primary"
            @click="isNewBookingDialogShow = true"
          >
            <v-icon icon="mdi-text-box-plus-outline" />New Booking
          </v-btn>
          <v-btn
            class="ms-auto quick-action-button"
            :to="{
              name: 'frontdeskClassroomDetail',
              params: { classId: selectedEvent.event.id },
            }"
            ><v-icon icon="mdi-account-details-outline"></v-icon>More</v-btn
          >
          <div class="ms-auto quick-action-button">
            <v-switch
              label="Active"
              color="primary"
              v-model="selectedEvent.event.isActive"
              @click="onActiveSwitchClickeed"
            ></v-switch>
          </div>
        </div>
      </div>
      <v-divider />
      <v-data-table
        :headers="dataHeader"
        :items="dataTable"
        hide-default-header
      >
        <template #[`item.action`]="{ item }">
          <v-btn
            icon="mdi-account-details-outline"
            variant="outlined"
            class="data-table-icon-button me-3"
            :to="{
              name: 'frontdeskMemberDetail',
              params: { memberId: item.memberId },
            }"
          ></v-btn>
          <v-dialog max-width="500">
            <template #activator="{ props: activatorProps }">
              <v-btn
                v-bind="activatorProps"
                icon="mdi-trash-can-outline"
                variant="outlined"
                color="error"
                class="data-table-icon-button"
              />
            </template>
            <template #default="{ isActive }">
              <v-card title="Booking Reject Confirmation">
                <v-card-text>
                  Please confirm that you really want to reject this booking?
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    text="reject Booking"
                    @click="
                      isActive.value = false;
                      onClassReject(item);
                    "
                  />
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </template>
      </v-data-table>
    </v-sheet>
  </v-bottom-sheet>
  <create-classroom-event-dialog
    :value="isNewClassDialogShow"
    @close="isNewClassDialogShow = false"
    @new-class="onNewClass"
  />
  <create-classroom-booking-dialog
    :value="isNewBookingDialogShow"
    :event-id="selectedEvent.event.id"
    @make-booking="onMakeBooking"
    @cancel-booking="onCancelBooking"
  />
</template>

<script setup>
import moment from "moment";
import { reactive, ref, watch } from "vue";
import BackendConnector from "@/util/BackendConnector";
import CreateClassroomEventDialog from "@/components/frontdesk/CreateClassroomEventDialog.vue";
import CreateClassroomBookingDialog from "@/components/frontdesk/CreateClassroomBookingDialog.vue";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import { authAxios } from "@/util/Axios";

const axios = authAxios();

const events = ref([]);

const instructorFilter = ref("all");
const instructors = reactive([{ title: "All", value: "all" }]);

watch(instructorFilter, (newValue) => {
  let startDate = moment(calendarObject.startDate).format("YYYY-MM-DD");
  let endDate = moment(calendarObject.endDate).format("YYYY-MM-DD");
  loadClassroomEvent(startDate, endDate, newValue).then((e) => {
    events.value = e;
  });
});

BackendConnector.loadInstructor().then((response) => {
  response.data.data.forEach((element) => {
    instructors.push({ title: element.name, value: element.id });
  });
});

const dataTable = ref([]);
const dataHeader = reactive([
  { title: "Name", value: "name", key: "name" },
  { title: "Action", key: "action", align: "end", nowrap: true },
]);

const isOffCanvasShow = ref(false);
const isNewClassDialogShow = ref(false);
const isNewBookingDialogShow = ref(false);
const selectedEvent = reactive({ event: {} });

var calendarObject = null;

function onActiveSwitchClickeed() {
  patchClassroomActiveStatus(
    selectedEvent.event.id,
    !selectedEvent.event.isActive
  ).then(() => {});
}

function patchClassroomActiveStatus(classId, isActive) {
  return axios({
    method: "PATCH",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/classroom_event/id-${classId}`,
    data: { is_active: isActive },
  });
}

function onVueCalReady(calObject) {
  calendarObject = calObject;
  let startDate = moment(calObject.startDate).format("YYYY-MM-DD");
  let endDate = moment(calObject.endDate).format("YYYY-MM-DD");
  loadClassroomEvent(startDate, endDate, instructorFilter.value).then((e) => {
    events.value = e;
  });
}

function onVueCalViewChange(calObject) {
  calendarObject = calObject;
  let startDate = moment(calObject.startDate).format("YYYY-MM-DD");
  let endDate = moment(calObject.endDate).format("YYYY-MM-DD");
  loadClassroomEvent(startDate, endDate, instructorFilter.value).then((e) => {
    events.value = e;
  });
}

function onMakeBooking() {
  isNewBookingDialogShow.value = false;
  loadAttendee(selectedEvent.event.id).then((attendees) => {
    dataTable.value = attendees;
  });
}

function onCancelBooking() {
  isNewBookingDialogShow.value = false;
}

function onClassReject(attendee) {
  callClassReject(attendee.bookingId).then(() => {
    loadAttendee(selectedEvent.event.id).then((attendees) => {
      dataTable.value = attendees;
    });
  });
}

function callClassReject(bookingId) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/classroom_booking/id-${bookingId}/rejected`,
    data: { booking_id: bookingId },
  });
}

function onEventClick(event, e) {
  isOffCanvasShow.value = true;
  selectedEvent.event = event;
  loadAttendee(event.id).then((attendees) => {
    dataTable.value = attendees;
  });
  e.stopPropagation();
}

function onNewClass() {
  isNewClassDialogShow.value = false;
  let startDate = moment(calendarObject.startDate).format("YYYY-MM-DD");
  let endDate = moment(calendarObject.endDate).format("YYYY-MM-DD");
  loadClassroomEvent(startDate, endDate, instructorFilter.value).then((e) => {
    events.value = e;
  });
}

async function loadAttendee(eventId) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/classroom_event/id-${eventId}/attendee`,
    withCredentials: true,
  }).then((response) => {
    var result = [];
    response.data.forEach((attendee) => {
      result.push({
        memberId: attendee.member_id,
        name: attendee.attendee,
        bookingId: attendee.booking_id,
      });
    });
    return result;
  });
}

async function loadClassroomEvent(
  startDate,
  endDate,
  instructorFilter = "all"
) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/classroom_event/calendar`,
    params: {
      start_date: startDate,
      end_date: endDate,
      instructor: instructorFilter,
    },
    withCredentials: true,
  }).then((response) => {
    var result = [];
    response.data.forEach((e) => {
      result.push({
        start: moment(e.datetime).format("YYYY-MM-DD HH:mm"),
        end: moment(e.datetime)
          .add(e.topic_period, "m")
          .format("YYYY-MM-DD HH:mm"),
        classPeriod:
          moment(e.datetime).format("MMM DD, YYYY HH:mm") +
          " - " +
          moment(e.datetime).add(e.topic_period, "m").format("HH:mm"),
        title: `${e.topic_name} ${e.current_participant}/${e.topic_max_participant}`,
        id: e.id,
        instructor: e.instructor_name,
        isActive: e.is_active,
        class: `bg-${e.topic_color}`,
      });
    });
    return result;
  });
}
</script>

<style scoped>
.quick-action-button {
  margin: 0.5rem;
}

.data-table-icon-button {
  font-size: 1.2rem;
}
</style>
