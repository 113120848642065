<template>
  <v-sheet class="text-start" v-if="member">
    <page-head
      :title="`${member.first_name} ${member.last_name}`"
      :breadcrumb="['Membership', 'More Detail']"
    >
    </page-head>
    <v-tabs v-model="tab" color="red-darken-4">
      <v-tab value="detail">Detail</v-tab>
      <v-tab value="transaction" v-if="props.shop.feature_enable.class_event">
        Classroom Transaction
      </v-tab>
      <v-tab
        value="open_private_transaction"
        v-if="props.shop.feature_enable.open_private_event"
      >
        Open Private Transaction
      </v-tab>
      <v-tab value="note">Note</v-tab>
    </v-tabs>
    <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="detail">
          <v-container fluid>
            <patch-input
              label="ID"
              :default="member.id"
              :readonly="true"
            ></patch-input>
            <patch-input
              label="First Name"
              :default="member.first_name"
              :readonly="true"
            >
            </patch-input>
            <patch-input
              label="Last Name"
              :default="member.last_name"
              :readonly="true"
            >
            </patch-input>
            <patch-input label="Phone" :default="member.phone" :readonly="true">
            </patch-input>
            <patch-input label="Email" :default="member.email" :readonly="true">
            </patch-input>
            <patch-input
              label="Register Date"
              :default="moment(member.create_dt).format('MMM DD, YYYY')"
              :readonly="true"
            >
            </patch-input>
          </v-container>
        </v-tabs-window-item>
        <v-tabs-window-item value="transaction">
          <member-history-tab :member-id="$route.params.memberId">
          </member-history-tab>
        </v-tabs-window-item>
        <v-tabs-window-item value="open_private_transaction">
          <member-open-private-history-tab :member-id="$route.params.memberId">
          </member-open-private-history-tab>
        </v-tabs-window-item>
        <v-tabs-window-item value="note">
          <v-list lines="two">
            <div class="d-flex justify-end">
              <v-dialog max-width="500">
                <template #activator="{ props: activatorProps }">
                  <v-btn
                    color="primary"
                    variant="outlined"
                    v-bind="activatorProps"
                  >
                    <v-icon icon="mdi-note-plus-outline"></v-icon>Add Note
                  </v-btn>
                </template>
                <template #default="{ isActive }">
                  <v-card
                    class="pa-5"
                    prepend-icon="mdi-note-plus-outline"
                    title="Add Member Note"
                  >
                    <v-sheet class="d-flex flex-column">
                      <v-textarea
                        label="Note"
                        variant="outlined"
                        v-model="noteTextarea"
                      ></v-textarea>
                    </v-sheet>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn @click="isActive.value = false">Cancel</v-btn>
                      <v-btn
                        @click="
                          isActive.value = false;
                          onNoteSaved();
                        "
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
            <v-list-item
              v-for="note in notes"
              :key="note.id"
              :title="moment(note.create_dt).format('MMM DD, YYYY HH:mm')"
              :subtitle="note.note"
            ></v-list-item>
          </v-list>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-sheet>
</template>

<script setup>
import moment from "moment";
import PageHead from "@/components/admin_theme/PageHead.vue";
import PatchInput from "@/components/admin_theme/patch/PatchInput.vue";
import MemberHistoryTab from "@/components/MemberHistoryTabs.vue";
import MemberOpenPrivateHistoryTab from "@/components/MemberOpenPrivateHistoryTabs.vue";
import { authAxios } from "@/util/Axios";
import { defineProps, ref } from "vue";
import { useRoute } from "vue-router";

const axios = authAxios();
const route = useRoute();
const tab = ref(null);

const member = ref(null);
const notes = ref(null);
const noteTextarea = ref(null);

const props = defineProps(["shop"]);

loadNote(route.params.memberId).then((response) => {
  notes.value = response.data;
});

loadMember(route.params.memberId).then((response) => {
  member.value = response.data;
});

function loadNote(memberId) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/member/id-${memberId}/note`,
  });
}

function loadMember(memberId) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/member/id-${memberId}`,
  });
}

function onNoteSaved() {
  makeMemberNote(member.value.id, noteTextarea.value).then(() => {
    noteTextarea.value = "";
    loadNote(route.params.memberId).then((response) => {
      notes.value = response.data;
    });
  });
}

function makeMemberNote(memberId, note) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/member/id-${memberId}/note`,
    data: { note: note },
  });
}
</script>
