<template>
  <app-bar title="Member Registration" />
  <v-sheet class="ma-3 pa-3 bg-transparent">
    <h5 class="text-start mb-5">
      Please input the following information for register as a new member.
    </h5>
    <v-form ref="form" @submit.prevent="onRegister">
      <v-text-field
        v-model="firstName"
        label="First Name"
        variant="outlined"
        :rules="RULES.notEmpty"
        class="text-start mb-3"
      />
      <v-text-field
        v-model="lastName"
        label="Last Name"
        variant="outlined"
        :rules="RULES.notEmpty"
        class="text-start mb-3"
      />
      <v-text-field
        v-model="phone"
        label="Phone Number"
        variant="outlined"
        :rules="RULES.phone"
        class="text-start mb-3"
      />
      <v-text-field
        v-model="email"
        label="Email"
        variant="outlined"
        class="text-start mb-3"
      />
      <v-btn
        size="large"
        block
        variant="outlined"
        type="submit"
        :loading="isFormLoading"
      >
        Register
      </v-btn>
    </v-form>
  </v-sheet>
</template>

<script setup>
import AppBar from "@/components/AppBar.vue";
import axios from "axios";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { RULES } from "@/util/Form";

const route = useRoute();
const router = useRouter();
const form = ref(null);
const isFormLoading = ref(false);

const firstName = ref("");
const lastName = ref("");
const phone = ref("");
const email = ref("");

async function onRegister() {
  const { valid } = await form.value.validate();
  if (valid) {
    isFormLoading.value = true;
    registerMember(
      route.params.shopId,
      route.query.lineId,
      firstName.value,
      lastName.value,
      phone.value,
      email.value
    )
      .then((response) => {
        let memberId = response.data.id;
        router.push({
          name: route.query.nextPage,
          params: { shopId: route.params.shopId, memberId: memberId },
          query: { memberId: memberId },
        });
      })
      .catch((error) => {
        isFormLoading.value = false;
        alert(error.response.data.detail);
      });
  }
}

function registerMember(shopId, lineId, firstName, lastName, phone, email) {
  let data = {
    shop_id: shopId,
    line_id: lineId,
    first_name: firstName,
    last_name: lastName,
    phone: phone,
    email: email,
  };
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/member/`,
    data: data,
  });
}
</script>
