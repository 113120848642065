<template>
  <v-sheet class="text-start">
    <page-head
      :title="classroom.topic_name"
      :breadcrumb="['Classroom', 'More Detail']"
      v-if="classroom"
    >
    </page-head>
    <v-tabs
      v-model="tab"
      color="red-darken-4"
      v-if="classroom && branchs && topics && instructors"
    >
      <v-tab value="detail">Detail</v-tab>
      <v-tab value="appearance">Appearance</v-tab>
    </v-tabs>
    <v-card-text v-if="classroom && branchs && topics && instructors">
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="detail">
          <v-container fluid>
            <patch-input label="ID" :default="classroom.id" :readonly="true">
            </patch-input>
            <patch-select
              label="Branch"
              patch-key="branch_id"
              :default="classroom.branch_id"
              :items="branchs"
              :update-func="onUpdate"
            >
            </patch-select>
            <patch-select
              label="Topic"
              patch-key="topic_id"
              :default="classroom.topic_id"
              :items="topics"
              :update-func="onUpdate"
            >
            </patch-select>
            <patch-select
              label="Instructor"
              patch-key="instructor_id"
              :default="classroom.instructor_id"
              :items="instructors"
              :update-func="onUpdate"
            >
            </patch-select>
            <patch-date
              label="Date"
              :default="new Date(classroom.datetime)"
              patch-key="datetime"
              :update-func="onUpdateDate"
            ></patch-date>
            <patch-time
              label="Time"
              :default="moment(classroom.datetime).format('HH:mm')"
              patch-key="datetime"
              :update-func="onUpdateTime"
            >
            </patch-time>
          </v-container>
        </v-tabs-window-item>
        <v-tabs-window-item value="appearance">
          <v-container fluid>
            <patch-input
              label="Summary Note"
              patch-key="summary_note"
              :default="classroom.summary_note"
              :update-func="onUpdate"
            ></patch-input>
          </v-container>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-sheet>
</template>

<script setup>
import moment from "moment";
import PageHead from "@/components/admin_theme/PageHead.vue";
import PatchInput from "@/components/admin_theme/patch/PatchInput.vue";
import PatchSelect from "@/components/admin_theme/patch/PatchSelect.vue";
import PatchDate from "@/components/admin_theme/patch/PatchDate.vue";
import PatchTime from "@/components/admin_theme/patch/PatchTime.vue";

import BackendConnector from "@/util/BackendConnector";

import { authAxios } from "@/util/Axios";
import { ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const axios = authAxios();
const tab = ref(null);
const classroom = ref(null);
const branchs = ref(null);
const topics = ref(null);
const instructors = ref(null);

BackendConnector.loadBranch().then((response) => {
  var result = [];
  response.data.data.forEach((element) => {
    result.push({ title: element.name, value: element.id });
  });
  branchs.value = [...result];
});

BackendConnector.loadTopic().then((response) => {
  var result = [];
  response.data.data.forEach((element) => {
    result.push({ title: element.name, value: element.id });
  });
  topics.value = [...result];
});

BackendConnector.loadInstructor().then((response) => {
  var result = [];
  response.data.data.forEach((element) => {
    result.push({ title: element.name, value: element.id });
  });
  instructors.value = [...result];
});

BackendConnector.loadClassroom(route.params.classId).then((response) => {
  classroom.value = response.data;
});

function onUpdateDate(key, value) {
  let hourStr = moment(classroom.value.datetime).format("HH:mm");
  let newDt = joinDateTime(value, hourStr);
  classroom.value.datetime = newDt;
  return onUpdate(key, newDt);
}

function onUpdateTime(key, value) {
  let newDt = joinDateTime(classroom.value.datetime, value);
  classroom.value.datetime = newDt;
  return onUpdate(key, newDt);
}

function onUpdate(key, value) {
  return axios({
    method: "PATCH",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/classroom_event/id-${route.params.classId}`,
    data: { [key]: value },
  });
}

function joinDateTime(d, t) {
  console.log(d, t);
  let dStr = moment(d).format("YYYY-MM-DD");
  let dtStr = dStr + " " + t;
  console.log(dtStr);
  return moment.utc(dtStr, "YYYY-MM-DD HH:mm");

  /*
  let dStr = moment(d).format("YYYY-MM-DD");
  let dtStr = dStr + " " + t;
  return moment.utc(dtStr, "YYYY-MM-DD HH:mm");
  */
}
</script>
