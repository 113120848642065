export const RULES = {
  email: [(v) => /.+@.+/.test(v) || "Invalid email address"],
  id: [(v) => /^\w+$/.test(v) || "Invalid ID"],
  phone: [
    (v) =>
      /^0\d+$/.test(v) || "Phone must start with 0 and contain 9 - 12 digits.",
  ],
  select: [(v) => !!v || "Item is required"],
  notEmpty: [(v) => !!v || "Field is required"],
  digit: [(v) => /^\d+$/.test(v) || "Only digit allow"],
};
