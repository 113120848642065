<template>
  <app-bar title="Open Private Form" :member-id="$route.query.memberId" />

  <v-overlay :model-value="isLoading" class="align-center justify-center">
    <v-progress-circular color="white" size="64" indeterminate />
  </v-overlay>

  <div class="booking-card d-flex flex-column">
    <v-select
      v-if="branchs.length > 1"
      v-model="selectedBranch"
      label="Branch"
      :items="branchs"
      variant="solo"
      prepend-inner-icon="mdi-map-marker"
    />
    <transition name="fade">
      <v-select
        v-if="stepReach('BRANCH_SELECTED')"
        v-model="selectedInstructor"
        label="Instructor"
        :items="instructors"
        variant="solo"
        prepend-inner-icon="mdi-clipboard-account"
      />
    </transition>
    <transition name="fade">
      <v-select
        v-if="stepReach('INSTRUCTOR_SELECTED')"
        v-model="selectedTopic"
        label="Topic"
        :items="topics"
        variant="solo"
        prepend-inner-icon="mdi-book-outline"
      />
    </transition>
    <transition name="fade">
      <v-date-input
        v-if="stepReach('TOPIC_SELECTED')"
        v-model="selectedDate"
        label="Date"
        variant="solo"
        :hide-actions="true"
        prepend-icon=""
        prepend-inner-icon="$calendar"
        :allowed-dates="allowedDates"
      />
    </transition>
    <transition name="fade">
      <v-select
        v-if="stepReach('DATE_SELECTED')"
        v-model="selectedTime"
        label="Time"
        :items="times"
        variant="solo"
        prepend-inner-icon="mdi-clock-outline"
      ></v-select>
    </transition>
    <v-spacer></v-spacer>
    <transition name="fade">
      <v-btn
        v-if="stepReach('TIME_SELECTED')"
        class="booking-submit-btn"
        block
        size="large"
        :color="props.ui.secondaryColor"
        @click="submitOpenPrivateInfo"
      >
        Book
      </v-btn>
    </transition>
  </div>
</template>

<script setup>
import AppBar from "@/components/AppBar.vue";
import BackendConnector from "@/util/BackendConnector";
import moment from "moment";
import { defineProps, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const props = defineProps(["ui"]);
const memberId = route.query.memberId;

const branchs = reactive([]);
const instructors = reactive([]);
const topics = reactive([]);
const allowedDates = reactive([]);
const times = reactive([]);

const selectedBranch = ref(null);
const selectedInstructor = ref(null);
const selectedTopic = ref(null);
const selectedDate = ref(null);
const selectedTime = ref(null);
const isLoading = ref(false);

const STEP = [
  "NOTHING_SELECTED",
  "BRANCH_SELECTED",
  "INSTRUCTOR_SELECTED",
  "TOPIC_SELECTED",
  "DATE_SELECTED",
  "TIME_SELECTED",
];
const currentStep = ref(0);

isLoading.value = true;
BackendConnector.loadBranchs(route.params.shopId).then((response) => {
  console.log("Response: ", response);
  Object.assign(branchs, [...response]);
  if (branchs.length == 1) {
    selectedBranch.value = response[0].value;
  }
  isLoading.value = false;
});

watch(selectedBranch, (newValue) => {
  currentStep.value = STEP.indexOf("NOTHING_SELECTED");
  selectedInstructor.value = null;
  selectedTopic.value = null;
  selectedDate.value = null;
  selectedTime.value = null;
  isLoading.value = true;
  BackendConnector.loadInstructors(newValue).then((response) => {
    Object.assign(instructors, [...response]);
    isLoading.value = false;
    currentStep.value = STEP.indexOf("BRANCH_SELECTED");
  });
});

watch(selectedInstructor, (newValue) => {
  currentStep.value = STEP.indexOf("NOTHING_SELECTED");
  selectedTopic.value = null;
  selectedDate.value = null;
  selectedTime.value = null;
  isLoading.value = true;
  BackendConnector.loadTopics(newValue).then((response) => {
    Object.assign(topics, [...response]);
    isLoading.value = false;
    currentStep.value = STEP.indexOf("INSTRUCTOR_SELECTED");
  });
});

watch(selectedTopic, () => {
  currentStep.value = STEP.indexOf("NOTHING_SELECTED");
  selectedDate.value = null;
  selectedTime.value = null;
  isLoading.value = true;
  BackendConnector.loadOpenPrivateAllowedDate(selectedInstructor.value).then(
    (response) => {
      Object.assign(allowedDates, [...response.data]);
      isLoading.value = false;
      currentStep.value = STEP.indexOf("TOPIC_SELECTED");
    }
  );
});

watch(selectedDate, (newValue) => {
  currentStep.value = STEP.indexOf("NOTHING_SELECTED");
  selectedTime.value = null;
  isLoading.value = true;
  BackendConnector.loadOpenPrivateAvailableTime(
    selectedInstructor.value,
    selectedTopic.value,
    moment(newValue).format("YYYY-MM-DD")
  ).then((response) => {
    Object.assign(times, [...response]);
    isLoading.value = false;
    currentStep.value = STEP.indexOf("DATE_SELECTED");
  });
});

watch(selectedTime, () => {
  currentStep.value = STEP.indexOf("TIME_SELECTED");
});

function submitOpenPrivateInfo() {
  isLoading.value = true;
  BackendConnector.makeOpenPrivateBooking(
    selectedBranch.value,
    selectedInstructor.value,
    selectedTopic.value,
    moment(selectedDate.value).format("YYYY-MM-DD"),
    selectedTime.value,
    memberId
  )
    .then((response) => {
      if (response) {
        router.push({
          name: "openPrivateSummary",
          params: { bookingId: response.data.booking_id },
        });
      }
    })
    .finally(() => {
      isLoading.value = false;
    });
}

function stepReach(step) {
  return currentStep.value >= STEP.indexOf(step);
}
</script>

<style scoped>
.booking-card {
  margin: 2rem 1rem;
}

.booking-submit-btn {
  margin-top: 2rem !important;
}
</style>
