<template>
  <div class="d-flex justify-end">
    <v-btn
      id="create-classroom-event"
      class="quick-action-button"
      color="primary"
      @click="isNewBookingDialogShow = true"
    >
      <v-icon icon="mdi-plus-box-outline" />New Booking
    </v-btn>
  </div>
  <vue-cal
    :time-from="5 * 60"
    :time-to="22 * 60"
    :disable-views="['years', 'year', 'month']"
    active-view="week"
    today-button
    :events="bookings"
    :on-event-click="onEventClick"
    :dblclick-to-navigate="false"
    @ready="onVueCalViewChange"
    @view-change="onVueCalViewChange"
  />
  <v-bottom-sheet v-model="isOffCanvasShow" inset>
    <v-sheet class="pa-5">
      <div class="d-flex">
        <div>
          <h2>
            {{ selectedEvent.event.title }}
          </h2>
          <div>
            {{ moment(selectedEvent.event.date).format("MMM DD, YYYY") }}
          </div>
          <div v-for="(s, idx) in selectedEvent.event.all_start_time" :key="s">
            {{ moment(s, "HH:mm:ss").format("HH:mm") }} -
            {{
              moment(selectedEvent.event.all_end_time[idx], "HH:mm:ss").format(
                "HH:mm"
              )
            }}
          </div>
        </div>
        <div class="ms-auto d-flex flex-column">
          <v-btn
            class="quick-action-button"
            color="error"
            @click="isRejectBookingDialogShow = true"
          >
            <v-icon icon="mdi-trash-can-outline" />Reject
          </v-btn>
        </div>
      </div>
    </v-sheet>
  </v-bottom-sheet>
  <v-dialog v-model="isRejectBookingDialogShow" max-width="400">
    <v-card prepend-icon="mdi-trash-can-outline" title="Reject Booking Confirm">
      <v-sheet class="d-flex flex-column ma-3">
        <p>Please confirm that you want to reject this booking.</p>
      </v-sheet>
      <template #actions>
        <v-btn
          class="ms-auto"
          text="Cancel"
          @click="isRejectBookingDialogShow = false"
        />
        <v-btn text="Reject" @click="onRejectSubmit" />
      </template>
    </v-card>
  </v-dialog>
  <create-open-private-booking-dialog
    :value="isNewBookingDialogShow"
    @cancel-booking="isNewBookingDialogShow = false"
    @make-booking="onNewBooking"
  ></create-open-private-booking-dialog>
</template>

<script setup>
import { authAxios } from "@/util/Axios";
import moment from "moment";
import { reactive, ref } from "vue";
import VueCal from "vue-cal";
import CreateOpenPrivateBookingDialog from "@/components/frontdesk/CreateOpenPrivateBookingDialog.vue";

const axios = authAxios();

const bookings = ref([]);
var calendarObject = null;

const isOffCanvasShow = ref(false);
const selectedEvent = reactive({ event: {} });

const isRejectBookingDialogShow = ref(false);
const isNewBookingDialogShow = ref(false);

function onEventClick(event, e) {
  isOffCanvasShow.value = true;
  selectedEvent.event = event;
  e.stopPropagation();
}

function onVueCalViewChange(calObject) {
  calendarObject = calObject;
  let startDate = moment(calendarObject.startDate).format("YYYY-MM-DD");
  let endDate = moment(calendarObject.endDate).format("YYYY-MM-DD");
  loadFacilityBooking(startDate, endDate).then((b) => {
    bookings.value = b;
  });
}

function onNewBooking() {
  isNewBookingDialogShow.value = false;
  onVueCalViewChange(calendarObject);
}

function onRejectSubmit() {
  isRejectBookingDialogShow.value = false;
  rejectFacilityBooking(selectedEvent.event.booking_id).then(() => {
    isOffCanvasShow.value = false;
    onVueCalViewChange(calendarObject);
  });
}

async function rejectFacilityBooking(bookingId) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/open_private_booking/id-${bookingId}/rejected`,
  });
}

async function loadFacilityBooking(startDate, endDate) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/open_private_booking/calendar`,
    params: { start_date: startDate, end_date: endDate },
  }).then((response) => {
    var result = [];
    response.data.forEach((e) => {
      result.push({
        start: moment(e.datetime).format("YYYY-MM-DD HH:mm"),
        end: moment(e.datetime).add(e.period, "m").format("YYYY-MM-DD HH:mm"),
        date: moment(e.datetime).format("YYYY-MM-DD"),
        title: `${e.attendee} - ${e.instructor}`,
        id: e.id,
        booking_id: e.booking_id,
        class: `bg-${e.topic_color}`,
      });
    });
    return result;
  });
}
</script>

<style scoped>
.quick-action-button {
  margin: 0.5rem;
}
</style>
